import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className='headings'>
          <h1 className='logo'>Joi</h1>
          <h4 className='subheading'>
            Explore your fantasies
          </h4>
        </div>
        <a
          className="Play-btn"
          href="https://twitter.com/joiapp"
          target="_blank"
          rel="noopener noreferrer"
        >
          Play
        </a>


        <div className="footer">
        <div className='footer-container'>
          <p className='footer-link'>Email Us</p>
          <p className='footer-link'>FAQ</p>
          <p className='footer-link'>Terms</p>
          <p className='footer-link'>Privacy</p>
        </div>
        <p className='tm'>© Joi Games, 2023.</p>
        </div>
      </header>
   
    </div>
  );
}

export default App;
